import { Image } from 'react-datocms'
import Section from '../../components/section'
import ShareButton from '../../components/share-button'
import StructuredText from '../../components/structured-text'
import UnderlinedLink from '../../components/underline-link'
import { SpotlightArticleMainModuleRecord } from '../../generated/graphql'
import formatDate from '../../utils/format-date'
import { isEmployeeCharacteristicRecord } from '../team-grid/team-grid'
import Dynamic from '../../components/dynamic'

type Props = {
  block: SpotlightArticleMainModuleRecord
}

function SpotlightArticleMainModule({ block }: Props) {
  const employee = block?.spotlightEmployeeWithData?.contentLayout
    .filter((employee) => employee?.id)
    .filter(isEmployeeCharacteristicRecord)[0]

  return (
    <Section>
      <div className="col-span-4 md:pr-5">
        <div className="mt-6 flex flex-row gap-3">
          <h2 className="text-greys-2">Career</h2>
          <h2 className="text-greys-2">/</h2>
          <h2>Talent spotlight - {employee?.name}</h2>
          <h2>
            <Dynamic>{formatDate(block._publishedAt, 'month-year')}</Dynamic>
          </h2>
        </div>

        <div className="mt-4">
          <h2 className="text-header-1">{block.headline}</h2>
        </div>

        <div
          className={`mt-6 ${
            employee?.profilePicture ? 'hidden' : ''
          } relative flex-col border-t-2 border-greys-1 pt-3 sm:flex sm:flex-row`}
        >
          <div className="">
            <ShareButton
              mobileColumn={true}
              text={block?.shareButtonLink?.text!}
              variant="primary"
              icons={block.shareButtonLink?.socialMediaButtons!}
            />
          </div>
        </div>

        <div className="mt-5">
          <StructuredText data={block.spotlightArticleText} />
        </div>

        {employee?.profilePicture && (
          <>
            <div className="relative -mx-2 mt-0 mt-5 block sm:mt-6 sm:hidden">
              {employee.profilePicture &&
                employee.profilePicture.responsiveImage && (
                  <Image
                    style={{ maxWidth: 'none' }}
                    data={employee.profilePicture.responsiveImage}
                  />
                )}

              <div className="absolute bottom-[1px] mt-6 flex h-10 w-full flex-col border-t-2 border-greys-0 border-opacity-20 bg-dusty-dark-blue-border bg-opacity-40 px-2 pt-3 sm:hidden sm:flex-row">
                <div className="absolute bottom-1 left-4 mt-3 pb-2 sm:mt-auto sm:ml-auto">
                  <ShareButton
                    mobileColumn={false}
                    text={block?.shareButtonLink?.text!}
                    variant="white"
                    icons={block.shareButtonLink?.socialMediaButtons!}
                  />
                </div>
              </div>
            </div>

            <div className="mt-4 flex flex-col gap-5 sm:hidden">
              <div className="flex flex-col">
                <h2 className="text-text-manchet">{employee?.name}</h2>
                <h2>{employee?.jobTitle}</h2>
              </div>

              <div className="flex flex-col gap-1">
                {employee.phoneNumber && (
                  <h2>
                    <UnderlinedLink
                      label={employee.phoneNumber}
                      link={`tel:${employee.phoneNumber}`}
                    />
                  </h2>
                )}

                {employee?.eMailAddress && (
                  <h2>
                    <UnderlinedLink
                      label="Email"
                      link={`mailto:${employee.eMailAddress}`}
                    />
                  </h2>
                )}

                {employee?.linkedin && (
                  <h2>
                    <UnderlinedLink
                      label="LinkedIn"
                      link={`https://${employee.linkedin}`}
                    />
                  </h2>
                )}
              </div>

              <div className="mt-4 flex flex-col gap-2">
                <h2 className="text text-text-manchet">Education</h2>
                <h2 className="text-text-base">{employee?.education}</h2>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="col-span-3 col-start-6 mt-0 hidden flex-col gap-3 sm:mt-6 sm:flex">
        {employee?.profilePicture &&
          employee.profilePicture.responsiveImage && (
            <Image
              style={{ maxWidth: 'none' }}
              data={employee.profilePicture.responsiveImage}
            />
          )}

        <div className="flex flex-col gap-5">
          <div className="flex flex-col">
            <h2 className="text-text-manchet">{employee?.name}</h2>
            <h2>{employee?.jobTitle}</h2>
          </div>

          <div className="flex flex-col gap-1">
            {employee?.phoneNumber && (
              <h2>
                <UnderlinedLink
                  label={employee.phoneNumber}
                  link={`tel:${employee.phoneNumber}`}
                />
              </h2>
            )}

            {employee?.eMailAddress && (
              <h2>
                <UnderlinedLink
                  label={employee.eMailAddress}
                  link={`mailto:${employee.eMailAddress}`}
                />
              </h2>
            )}
            {employee?.linkedin && (
              <h2>
                <UnderlinedLink
                  label={employee.linkedin}
                  link={`https://${employee.linkedin}`}
                />
              </h2>
            )}
          </div>

          <div className="mt-4 flex flex-col gap-2">
            <h2 className="text text-text-manchet">Education</h2>
            {employee?.education && (
              <h2 className="text-text-base">{employee.education}</h2>
            )}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SpotlightArticleMainModule
