import React, { useRef, useState, useEffect } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import ArrowLeft from '../../icons/arrow-left'
import ArrowRight from '../../icons/arrow-right'
import { FeaturedEventSwiperRecord } from '../../generated/graphql'
import EventsCard from '../events-card'
import { Skeleton } from '../events-card/events-card'

type Props = {
  block: FeaturedEventSwiperRecord
}

function SwiperWithSkeleton({ data = [], swiperRef }: any) {
  return (
    <div className="col-span-full">
      <Swiper
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper
        }}
        mousewheel={{ forceToAxis: true }}
        direction="horizontal"
        slidesPerView="auto"
        spaceBetween={40}
      >
        {data
          ? data.map((event: any) => {
              return (
                <SwiperSlide key={event.id} className="w-[345px] md:w-[445px]">
                  <EventsCard event={event} />
                </SwiperSlide>
              )
            })
          : Array(5)
              .fill(1)
              .map((_, index) => {
                return (
                  <SwiperSlide key={index} className="w-[345px] md:w-[445px]">
                    <Skeleton />
                  </SwiperSlide>
                )
              })}
      </Swiper>
    </div>
  )
}

function FeaturedWebinars({ block }: Props) {
  const [data, setData] = useState<any>(null)

  const swiperRef: any = useRef()

  useEffect(() => {
    fetch(`api/events/public-webinars`)
      .then((res) => res.json())
      .then(({ data }) => setData(data))
  }, [])

  return (
    <section className="mt-5 md:mt-10">
      <div className="mx-auto grid h-full max-w-fullbleed grid-cols-1 p-2 pr-0 md:p-5 md:pr-0">
        <div>
          <div className="mb-3 mr-2 flex justify-end gap-5 md:mr-5">
            <h2 className="w-full text-header-2">{block.title}</h2>
            <button
              onClick={() => swiperRef?.current?.slidePrev()}
              className="cursor-pointer disabled:opacity-20"
              disabled={false}
            > 
              <ArrowLeft />
            </button>
            <button
              onClick={() => swiperRef?.current?.slideNext()}
              className="cursor-pointer disabled:opacity-20"
              disabled={false}
            >
              <ArrowRight />
            </button>
          </div>
          <SwiperWithSkeleton data={data} swiperRef={swiperRef} />
        </div>
      </div>
    </section>
  )
}

export default FeaturedWebinars
