import Link from 'next/link'
import Image from 'next/image'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import EventListItem from '../../types/EventListItem'
import formatDate from '../../utils/format-date'
import Dynamic from '../../components/dynamic'

type Props = {
  event: EventListItem
}

const eventTypeDotColors = {
  webinar: 'bg-brand-data-blue-light',
  external: 'bg-primary-orange',
  other: 'bg-primary-rystad-blue-light-tint-2',
}

function EventsThumbnail({ event }: Props) {
  return (
    <Link
      scroll={false}
      key={event.id}
      href={`/events/${
        event.category.name === 'Webinar' ? 'webinars' : 'offline-events'
      }/${event.id}--${event.uniqueNameEncoded}`}
    >
      <div
        key={event.id}
        className="group relative flex flex-row overflow-hidden hover:cursor-pointer"
      >
        <div>
          <div className="absolute top-[-1px] h-[2px] w-full bg-greys-3" />

          <div className="absolute top-[-1px] h-[2px] w-full -translate-x-full bg-current transition-transform duration-700 group-hover:translate-x-[0]" />
        </div>

        <div className="mt-5 flex w-full flex-row justify-between gap-2">
          <div className="flex flex-col gap-5">
            <h2 className="text-header-3 line-clamp-3">{event.name}</h2>

            <div className="flex flex-row items-center justify-start gap-2 text-text-x-small">
              <div className="flex flex-row items-center gap-1">
                <div
                  className={`h-1 w-1 shrink-0 rounded-full ${
                    event.category.name.toLowerCase().includes('webinar')
                      ? eventTypeDotColors.webinar
                      : event.category.name.toLowerCase().includes('external')
                      ? eventTypeDotColors.external
                      : eventTypeDotColors.other
                  }`}
                ></div>
                <h2 className="transition-all duration-700 group-hover:text-primary-rystad-blue-light-tint">
                  {event.category.name}
                </h2>
              </div>

              <h2 className="text-greys-3">
                <Dynamic>
                  {formatDate(new Date(event.startDate), 'full')}
                </Dynamic>
              </h2>
            </div>
          </div>
          {!isEmpty(get(event, 'eventImageUrls.src')) && (
            <div className="relative aspect-[0.83] w-[120px] flex-shrink-0">
              <Image
                src={event?.eventImageUrls?.src}
                objectFit="cover"
                layout="fill"
              />
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

export default EventsThumbnail
