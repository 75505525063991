import { EmployeeCharacteristicRecord } from '../generated/graphql'
import Employee from './employee'
import UnderlinedLink from './underline-link'

type Props = {
  employee: EmployeeCharacteristicRecord
}

function EmployeeWithContactLinks({ employee }: Props) {
  return (
    <div className={'flex flex-col gap-4'}>
      <Employee person={employee} />
      <div className={'disable-special-hover flex flex-row gap-2'}>
        {employee.phoneNumber && (
          <UnderlinedLink
            link={`tel:${employee.phoneNumber}`}
            label={employee.phoneNumber}
            overrideClasses="disable-special-hover"
          />
        )}
        <div className="flex gap-2">
          {employee.eMailAddress && (
            <UnderlinedLink
              link={`mailto:${employee.eMailAddress}`}
              label="Email"
              overrideClasses="disable-special-hover"
            />
          )}
          {employee.linkedin && (
            <UnderlinedLink
              link={`https://${employee.linkedin}`}
              label="LinkedIn"
              overrideClasses="disable-special-hover"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default EmployeeWithContactLinks
