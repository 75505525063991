import Image from 'next/image'
import AutoplayVideo from '../../components/autoplay-video'
import CtaLink from '../../components/cta-link'
import type { CareersConsultancyFullWidthImageModuleTopRecord as CareersConsultancyFullWidthImageModuleTopRecordType } from '../../generated/graphql'

type Props = {
  block: CareersConsultancyFullWidthImageModuleTopRecordType
}

function CareersConsultancyFullWidthImageModuleTopRecord({ block }: Props) {
  return (
    <section
      className={`relative min-h-[500px] ${
        block.useFullHeight ? 'h-screen' : 'h-[60vh]'
      } z-[1]`}
      id={block?.slug || ''}
    >
      <div
        className={`absolute left-1/2 grid h-full min-h-[500px] ${
          block.useFullHeight ? 'h-full' : 'h-[60vh]'
        } w-full max-w-fullbleed -translate-x-1/2 grid-cols-2 gap-2 px-2 md:grid-cols-8 md:gap-5 md:px-5`}
      >
        <div className="col-span-full col-start-1 flex h-full w-full flex-col justify-center gap-3 py-2 md:col-span-3">
          <div className="mt-auto flex flex-col gap-3">
            <h2 className="text-text-small text-greys-0">{block?.label}</h2>
            <h1 className="text-header-1 text-greys-0">{block?.description}</h1>
          </div>

          <div className="mt-auto flex max-w-[70%] flex-col">
            {block &&
              block?.cta?.map((cta) => (
                <div
                  key={cta.id}
                  className={`w-fit py-3 ${
                    cta.showDivider ? 'border-t border-white/20' : ''
                  }`}
                >
                  <CtaLink cta={cta} />
                </div>
              ))}
          </div>
        </div>
      </div>
      <h2
        className={`absolute bottom-1 right-1 hidden p-4 text-text-small font-medium text-greys-0 md:block`}
      >
        Scroll for more
      </h2>

      {block.backgroundVideoLink && (
        <div className="relative z-[-1] h-screen w-auto min-w-full object-cover">
          <AutoplayVideo
            videoSrc={block.backgroundVideoLink!}
            fallBackImage={block.backgroundImage?.responsiveImage!}
          />
        </div>
      )}
      {block.backgroundImage && (
        <div
          className={`relative z-[-1] col-span-full min-h-[500px] ${
            block.useFullHeight ? 'h-full' : 'h-[60vh]'
          }`}
        >
          <Image
            className="h-full w-full object-cover"
            src={block.backgroundImage.responsiveImage?.src!}
            layout="fill"
            priority
          />
        </div>
      )}
      <div
        className={`absolute top-0 left-0 z-[-1] min-h-[500px] ${
          block.useFullHeight ? 'h-full' : 'h-[60vh]'
        } w-full bg-overlay`}
      />
    </section>
  )
}

export default CareersConsultancyFullWidthImageModuleTopRecord
