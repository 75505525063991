import { Image } from 'react-datocms'
import Section from '../components/section'
import UnderlinedLink from '../components/underline-link'
import { PressReleaseContactModuleRecord } from '../generated/graphql'
import { isEmployeeCharacteristicRecord } from './team-grid/team-grid'

type Props = {
  block: PressReleaseContactModuleRecord
}

function PressReleaseContactModule({ block }: Props) {
  const contacts = block?.contacts
    ? block?.contacts[0]?.pressReleaseSolutionContactOrAuthor
        ?.map(
          (author) => author.contentLayout.filter((content) => content.id)[0]
        )
        .filter(isEmployeeCharacteristicRecord)
    : []

  return (
    <Section id={block?.slug || ''}>
      <div className="col-span-2 sm:col-span-8">
        <h2 className="py-1 text-header-1 text-primary-rystad-blue">
          {block.headline}
          <sup className="font-light">
            {block?.contacts
              ? block?.contacts[0]?.pressReleaseSolutionContactOrAuthor?.length
              : ''}
          </sup>
        </h2>

        <div className="mt-3 mt-5 grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-4 lg:grid-cols-5">
          {contacts.map((contact) => (
            <div key={contact.id}>
              <Image
                style={{ maxWidth: 'none' }}
                data={contact.profilePicture?.responsiveImage!}
              />

              <h2 className="mt-3 text-header-4 text-primary-rystad-blue">
                {contact.name}
              </h2>

              <h2 className="mt-2 text-text-base text-primary-rystad-blue line-clamp-2">
                {contact.jobTitle}
              </h2>
              <div className="mt-2 flex flex-row gap-1">
                {contact.phoneNumber && (
                  <h2 className="mt-5 break-words text-text-x-small text-primary-rystad-blue">
                    <UnderlinedLink
                      label={contact.phoneNumber}
                      link={`tel:${contact.phoneNumber}`}
                    />
                  </h2>
                )}
                {contact.eMailAddress && (
                  <h2 className="break-words text-text-x-small text-primary-rystad-blue">
                    <UnderlinedLink
                      label="Email"
                      link={`mailto:${contact.eMailAddress}`}
                    />
                  </h2>
                )}
                {contact.linkedin && (
                  <div className="break-words text-text-x-small text-primary-rystad-blue">
                    <UnderlinedLink
                      label="Linkedin"
                      link={`https://${contact.linkedin}`}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  )
}

export default PressReleaseContactModule
