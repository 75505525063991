import Link from 'next/link'
import { Image } from 'react-datocms'
import { CardGridCardRecord } from '../../../generated/graphql'
import MenuRightArrow from '../../../icons/menu-right-arrow'

type Props = {
  card: CardGridCardRecord
}

function MobileCard({ card }: Props) {
  return (
    <Link scroll={true} key={card.id} href={card.link}>
      <div className="flex snap-start flex-row items-center gap-1 hover:cursor-pointer sm:hidden">
        <div className="w-2/6 shrink-0">
          <Image
            className="rounded-lg"
            objectFit="cover"
            data={card.image?.responsiveImage!}
          />
        </div>

        <div className="flex flex-col gap-1 px-2 py-1">
          <div className="flex flex-row items-center gap-1">
            <h2 className="text-text-base font-bold font-medium">{card.title}</h2>
            <MenuRightArrow />
          </div>
          <h2 className="text-text-small">{card.shortExcerpt}</h2>
        </div>
      </div>
    </Link>
  )
}

export default MobileCard
