export async function authenticateREEvents() {
  const details: Record<string, string> = {
    grant_type: 'client_credentials',
    client_id: process.env.NEXT_RE_CLIENT_ID ?? '',
    client_secret: process.env.NEXT_RE_EVENTS_SECRET ?? '',
  }

  let formBody: any = []
  for (const property in details) {
    const encodedKey = encodeURIComponent(property)
    const encodedValue = encodeURIComponent(details[property])
    formBody.push(encodedKey + '=' + encodedValue)
  }
  formBody = formBody.join('&')
  return await fetch(process.env.NEXT_RE_TOKEN_ENDPOINT ?? '', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: formBody,
  })
    .then((res) => res.json())
    .then((res) => res.access_token)
}

export async function fetchFromREEvents(query: string, token: string) {
  return fetch(process.env.NEXT_RE_ENDPOINT + query, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }).then((res) => res.json())
}

export async function fetchEventById(query: string) {
  return fetch(process.env.NEXT_RE_EVENT_ENDPOINT + query, {
    method: 'GET',
    headers: {
      RE_SECRET: process.env.NEXT_RE_EVENT_SECRET_KEY ?? '',
      'Content-Type': 'application/json',
    },
  }).then((res) => res)
}

export async function fetchUpcomingEvents(
  query: string,
  {
    'sales-region': salesRegion = '',
    industry = '',
    'energy-theme': energyTheme = '',
    type = '',
  } = {},
  isOnline = false
) {
  const currentDate = new Date().toISOString()
  const requestBody = JSON.stringify([
    {
      type: 'date',
      propertyName: 'startDate',
      operator: 'gt',
      value: currentDate,
    },
    {
      type: 'string',
      propertyName: 'salesRegion.name',
      value: salesRegion,
    },
    {
      type: 'generic',
      propertyName: 'industries.name',
      operator: 'in',
      value: industry,
    },
    {
      type: 'generic',
      propertyName: 'energyThemes.name',
      operator: 'in',
      value: energyTheme,
    },
    {
      type: 'boolean',
      propertyName: 'isInternal',
      value: type ? type === 'internal' : '',
    },
    {
      type: 'boolean',
      propertyName: 'isOnline',
      value: isOnline,
    },
  ])

  try {
    const res = await fetch(process.env.NEXT_RE_EVENT_ENDPOINT + query, {
      method: 'PUT',
      headers: {
        RE_SECRET: process.env.NEXT_RE_EVENT_SECRET_KEY ?? '',
        'Content-Type': 'application/json',
      },
      body: requestBody,
    })

    if (!res.ok) {
      throw new Error('Error fetching upcoming events')
    }

    const events = await res.json()

    const pagingHeaders = JSON.parse(res.headers.get('paging-headers') ?? '')

    return { events, pagingHeaders }
  } catch (error) {
    console.error('Error fetching upcoming events:', error)
    return { events: [], pagingHeaders: '' }
  }
}

export async function fetchPastEvents(
  query: string,
  {
    'sales-region': salesRegion = '',
    industry = '',
    'energy-theme': energyTheme = '',
    type = '',
  } = {},
  isOnline = false
) {
  const currentDate = new Date().toISOString()
  const requestBody = JSON.stringify([
    {
      type: 'date',
      propertyName: 'startDate',
      operator: 'lt',
      value: currentDate,
    },
    {
      type: 'string',
      propertyName: 'salesRegion.name',
      value: salesRegion,
    },
    {
      type: 'generic',
      propertyName: 'industries.name',
      operator: 'in',
      value: industry,
    },
    {
      type: 'generic',
      propertyName: 'energyThemes.name',
      operator: 'in',
      value: energyTheme,
    },
    {
      type: 'boolean',
      propertyName: 'isInternal',
      value: type ? type === 'internal' : '',
    },
    {
      type: 'boolean',
      propertyName: 'isOnline',
      value: isOnline,
    },
  ])

  try {
    const res = await fetch(process.env.NEXT_RE_EVENT_ENDPOINT + query, {
      method: 'PUT',
      headers: {
        RE_SECRET: process.env.NEXT_RE_EVENT_SECRET_KEY ?? '',
        'Content-Type': 'application/json',
      },
      body: requestBody,
    })

    if (!res.ok) {
      throw new Error('Error fetching past events')
    }
    const events = await res.json()

    const pagingHeaders = JSON.parse(res.headers.get('paging-headers') ?? '')

    return { events, pagingHeaders }
  } catch (error) {
    console.error('Error fetching past events:', error)
    return { events: [], pagingHeaders: '' }
  }
}

export async function fetchOnDemandWebinarEvents(
  query: string,
  {
    'sales-region': salesRegion = '',
    industry = '',
    'energy-theme': energyTheme = '',
    type = '',
  } = {}
) {
  const currentDate = new Date().toISOString()
  const requestBody = JSON.stringify([
    {
      type: 'date',
      propertyName: 'onDemandEndDate',
      operator: 'gt',
      value: currentDate,
    },
    {
      type: 'date',
      propertyName: 'startDate',
      operator: 'lt',
      value: currentDate,
    },
    {
      type: 'string',
      propertyName: 'salesRegion.name',
      value: salesRegion,
    },
    {
      type: 'generic',
      propertyName: 'industries.name',
      operator: 'in',
      value: industry,
    },
    {
      type: 'generic',
      propertyName: 'energyThemes.name',
      operator: 'in',
      value: energyTheme,
    },
    {
      type: 'boolean',
      propertyName: 'isInternal',
      value: type ? type === 'internal' : '',
    },
    {
      type: 'boolean',
      propertyName: 'isOnline',
      value: true,
    },
  ])

  try {
    const res = await fetch(process.env.NEXT_RE_EVENT_ENDPOINT + query, {
      method: 'PUT',
      headers: {
        RE_SECRET: process.env.NEXT_RE_EVENT_SECRET_KEY ?? '',
        'Content-Type': 'application/json',
      },
      body: requestBody,
    })

    if (!res.ok) {
      throw new Error('Error fetching on-demand webinar events')
    }

    const events = await res.json()

    const pagingHeaders = JSON.parse(res.headers.get('paging-headers') ?? '')

    return { events, pagingHeaders }
  } catch (error) {
    console.error('Error fetching on-demand webinar events:', error)
    return { events: [], pagingHeaders: '' }
  }
}
