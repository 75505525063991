import { ImageVideoRecord } from '../generated/graphql'
import VideoComponent from '../components/video-component'
import { Image } from 'react-datocms'

type Props = {
  block: ImageVideoRecord
}

function ImageVideoBlock({ block }: Props) {
  const {
    externalVideoLink,
    image: { responsiveImage },
  } = block

  if (externalVideoLink && responsiveImage) {
    return <VideoComponent src={externalVideoLink} poster={responsiveImage} />
  }

  if (responsiveImage) {
    return (
      <Image
        style={{ maxWidth: 'none' }}
        className="my-4 object-cover drop-shadow-sm"
        data={responsiveImage}
      />
    )
  }

  return <>Missing image or video</>
}

export default ImageVideoBlock
