import React from 'react'
import Image from 'next/image'
import get from 'lodash/get'
import { useRouter } from 'next/router'

import formatDate from '../../utils/format-date'
import Dynamic from '../../components/dynamic'
import FilledButton from '../../components/button'
import { slugify } from '../../pages/api/events'

const eventTypeDotColors = {
  webinar: 'bg-brand-data-blue-light',
  external: 'bg-primary-orange',
  other: 'bg-primary-rystad-blue-light-tint-2',
}

function Skeleton() {
  return (
    <div>
      <div className="animate-pulse overflow-hidden bg-greys-1">
        <div className="relative aspect-[1.5]">
          <div className="absolute inset-0 animate-pulse bg-greys-2"></div>
        </div>
        <div className="px-1">
          <div className="flex">
            <div className="my-4 h-3 w-2 rounded bg-greys-2"></div>
            <div className="my-4 mx-2 h-3 w-11 rounded bg-greys-2"></div>
            <div className="my-4 h-3 w-11 rounded bg-greys-2"></div>
          </div>
          <div className="h-3 w-full rounded bg-greys-2 pr-1"></div>
          <div className="mt-4 mb-1 w-[145px] justify-self-end">
            <div className="h-3 w-full rounded-large bg-greys-2 p-2"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

function EventsCard({ event }: any) {
  const router = useRouter()
  const imgSrc = get(event, 'eventImageUrls[0]', '/fallback.png')
  const getUrl = `/events/${
    event.category.name === 'Webinar' ? 'webinars' : 'offline-events'
  }/${event.id}--${slugify(event.name)}`

  function getButtonLabel(): string {
    const { isOnDemand, category } = event
    const { query } = router

    if(category.name == 'Webinar') {
      return isOnDemand ? 'Watch now' : 'Register now'
    }

    if (query.type === 'external') {
      return 'Learn more'
    }

    return 'Register now'
  }


  if (!imgSrc) return <></>

  return (
    <div
      className="cursor-pointer"
      key={event.id}
      onClick={() => router.push(getUrl)}
    >
      <div className="relative aspect-[1.5]">
        <Image src={imgSrc} objectFit="cover" layout="fill" />
      </div>
      <div>
        <div>
          <div className="flex flex-row items-center justify-start gap-2 text-text-x-small">
            <div className="my-4 flex flex-row items-center gap-1">
              <div
                className={`h-1 w-1 shrink-0 rounded-full ${
                  event.category.name.toLowerCase().includes('webinar')
                    ? eventTypeDotColors.webinar
                    : event.category.name.toLowerCase().includes('external')
                    ? eventTypeDotColors.external
                    : eventTypeDotColors.other
                }`}
              ></div>
              <h2 className="transition-all duration-700 group-hover:text-primary-rystad-blue-light-tint">
                {event.isOnDemand ? 'On Demand ' : 'Upcoming'}
              </h2>
            </div>
            <h2 className="text-greys-3">
              <Dynamic>{formatDate(new Date(event.startDate), 'full')}</Dynamic>
            </h2>
          </div>
          <h2 className="text-header-3">{event.name}</h2>
          <div className="mt-4 w-[145px] justify-self-end">
            <FilledButton
              variant="primary"
              className='px-2 py-1 w-fit'
              key={event.name}
              text={getButtonLabel()}
              isSmall
              to={getUrl}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventsCard
export { Skeleton }