import type { NextApiRequest, NextApiResponse } from 'next'
import {
  fetchOnDemandWebinarEvents,
  fetchPastEvents,
  fetchUpcomingEvents,
} from '../../../lib/re-events'

// function to turn name into slug
export const slugify = (name: string) => {
  return name
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

export default async function handler(
  req: NextApiRequest,
  res: NextApiResponse<any>
) {
  const NUMBER_OF_EVENTS = 15

  try {
    const { query, pagination = 0, isOnline } = req.body

    const requests = [
      fetchUpcomingEvents(
        `PublicEvents/search?page=${pagination}&pagesize=${NUMBER_OF_EVENTS}&sortBy=startDate&sortByAscending=true`,
        query,
        isOnline
      ),
    ]

    requests.push(
      isOnline
        ? fetchOnDemandWebinarEvents(
            `PublicEvents/search?page=${pagination}&pagesize=${NUMBER_OF_EVENTS}&sortBy=startDate&sortByAscending=false`,
            query
          )
        : fetchPastEvents(
            `PublicEvents/search?page=${pagination}&pagesize=${NUMBER_OF_EVENTS}&sortBy=startDate&sortByAscending=false`,
            query,
            isOnline
          )
    )
    // @todo split so we don't need to fetch both upcoming and past events at the same time
    const [
      rawUpcomingEvents = { events: [], pagingHeaders: '' },
      rawPastEvents = { events: [], pagingHeaders: '' },
    ] = await Promise.all(requests)



    const pastEvents = rawPastEvents.events.map((event: any) => ({
      name: event.name,
      id: event.id,
      category: event.category,
      startDate: event.startDate,
      uniqueNameEncoded: slugify(event.name),
      isInternal: event.isInternal,
      endDate: event.endDate,
      isOnDemand: event.isOnDemand,
      eventImageUrls: {
        src: event.eventImageUrls[0],
        width: 120,
        height: 144,
      },
    }))

    const upcomingEvents = rawUpcomingEvents.events.map((event: any) => ({
      name: event.name,
      id: event.id,
      category: event.category,
      startDate: event.startDate,
      uniqueNameEncoded: slugify(event.name),
      isInternal: event.isInternal,
      endDate: event.endDate,
      isOnDemand: event.isOnDemand,
      eventImageUrls: {
        src: event.eventImageUrls[0],
        width: 120,
        height: 144,
      },
    }))

    const paginationHeaders = {
      pastEvents: rawPastEvents.pagingHeaders,
      upcomingEvents: rawUpcomingEvents.pagingHeaders,
    }

    // return json response
    res.status(200).json({ pastEvents, upcomingEvents, paginationHeaders })
  } catch (error) {
    console.error(error)
    res.status(500).json({ error })
  }
}
