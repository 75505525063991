import { useRouter } from 'next/router'
import { Image } from 'react-datocms'
import Section from '../../components/section'
import UnderlinedLink from '../../components/underline-link'
import { EmployeeCharacteristicRecord } from '../../generated/graphql'
import EmployeeNavigation from './employee-navigation'
import StructuredText from '../../components/structured-text'

type Params = {
  block: EmployeeCharacteristicRecord
}

function EmployeeCharacteristics({ block }: Params) {
  const router = useRouter()
  const orderInfo = router.query.index?.toString() ?? ''
  const [index, employeesCount] = orderInfo.split('-').map(Number)
  return (
    <>
      {block.profilePicture?.responsiveImage && (
        <Image
          className="mb-4 block md:hidden"
          style={{ maxWidth: 'none' }}
          data={block.profilePicture?.responsiveImage!}
        />
      )}
      <Section>
        <div className="col-span-full col-start-1 hidden aspect-[375/515] md:col-span-4 md:row-span-2 md:row-start-1 md:block md:aspect-[543/648]">
          {block.profilePicture?.responsiveImage && (
            <Image
              style={{ maxWidth: 'none' }}
              data={block.profilePicture?.responsiveImage!}
            />
          )}
          <EmployeeNavigation
            left={`${
              index === 1 ? employeesCount : index - 1
            }-${employeesCount}`}
            right={`${
              index === employeesCount ? 1 : index + 1
            }-${employeesCount}`}
            name={block.name!}
          />
        </div>
        <div className="col-span-full md:col-start-5">
          <div className="items-start">
            <h1 className="text-display-2">{block.name}</h1>
            <p className="mt-1 text-text-small md:text-text-manchet">
              {block.jobTitle}
            </p>

            <div className="mt-4 flex flex-col gap-1 pb-9 md:border-b md:border-greys-1">
              {block.phoneNumber && (
                <UnderlinedLink
                  link={`tel:${block.phoneNumber}`}
                  label={block.phoneNumber}
                />
              )}
              {block.eMailAddress && (
                <UnderlinedLink
                  link={`mailto:${block.eMailAddress}`}
                  label="Email"
                />
              )}
              {block.linkedin && (
                <UnderlinedLink
                  link={`https://${block.linkedin}`}
                  label="LinkedIn"
                />
              )}
            </div>
          </div>
          <div className="pb-4 md:pt-9">
            <StructuredText data={block.personalDescription?.value} />
          </div>
        </div>
      </Section>
    </>
  )
}

export default EmployeeCharacteristics
