import BlockType from '../types/Block'
import FrontPageKeyNewsElement from './front-page-key-news-element'
import FrontPageServiceScrollAnimation from './front-page-service-scroll-animation'
import FrontPageTopModule from './front-page-top-module'
import PressReleaseArticle from './press-release-article'
import PressReleaseRelatedNews from './press-release-related-news'
import SolutionsEnergyTypesModule from './solutions-energy-types-module'
import SolutionHowWeDeliverValue from './solution-how-we-deliver-value'
import IntroDescriptionRecord from './intro-description-record'
import PeopleVideoModule from './people-video-module'
import RichTextWidgetRecord from './rich-text-widget-record'
import TeamGrid from './team-grid'
import EmployeeCharacteristics from './employee-characteristic'
import EmployeePublicationRecord from './employee-publication-record'
import CtaLinkRecord from './cta-link-record'
import SolutionVideoImageModuleWithSideCaption from './solution-video-image-module-with-side-caption'
import CareersAboutModule from './careers-about-module'
import SolutionPageImageVideoHeader from './solution-page-image-video-header'
import SolutionsIndustryModule from './solutions-industry-module/solutions-industry-module'
import PressReleaseContactModule from './press-release-contact-module'
import SolutionSubmenu from './solution-submenu'
import Cta from './cta'
import PageHeader from './page-header'
import ContactsSliderWithCta from './contacts-slider-with-cta/contacts-slider-with-cta'
import FrontPageImageSlider from './front-page-image-slider'
import ImageTextCtaModule from './image-text-cta-module'
import PressReleaseOverviewNewsGrid from './press-release-overview-news-grid'
import PressReleaseOverviewNewsletter from './press-release-overview-newsletter'
import ServicesMenu from './service-menu'
import CareersOverviewPageJobPostingGrid from './careers-overview-page-job-posting-grid'
import DividedFeatures from './divided-features'
import TopModuleWithIntroDescriptionAndVideoImageModuleRecord from './top-module-with-intro-description-and-video-image-module-record'
import CareersConsultancyFullWidthImageModuleTopRecord from './careers-consultancy-full-width-image-module-top-record'
import PageNotFound from './page-not-found'
import CareersOverviewPageJoinUsWidgetRecord from './careers-overview-page-join-us-widget-record'
import CareersHorizontalScrollRecord from './careers-horizontal-scroll-record'
import CareersSpotlightModule from './careers-spotlight-module'
import IndustryEnergyPageSolutionGridRecord from './industry-energy-page-solution-grid-record'
import PreFooterBlockRecord from './footer/pre-footer-block-record'
import SearchResult from './search-result/search-result'
import FrontPageFindTheRightSolutionRecord from './front-page-find-the-right-solution-record'
import ContactPageIntroductionTopModule from './contact-page-introduction-top-module'
import ContactPageContactInformationPerBusinessAreaModule from './contact-page-contact-information-per-business-area-module'
import ContactPageLocationBlock from './contact-page-location'
import RichTextWidgetWithoutCta from './rich-text-widget-without-cta'
import EventsGridRecord from './events-grid-record'
import IndustryGridRecord from './industry-and-energy-type-grid/industry-grid-record'
import EnegyTypeGridRecord from './industry-and-energy-type-grid/enegy-type-grid-record'
import TxtBlockRecord from './txt-block-record'
import ClientTestimonialRecord from './client-testimonial-record'
import LogoModuleRecord from './logo-module-record'
import SpotlightArticleMainModule from './spotlight-article-main-module'
import OtherSpotlightsModuleRecord from './other-spotlights-module-record'
import SolutionRelatedInsightRecord from './solution-related-insight-record'
import SugarMarketProductDemoFrom from './sugar-market-product-demo-form'
import SugarMarketNewsletterForm from './sugar-market-newsletter-form'
import SequencingSentences from './sequencing-sentences'
import UniverseAnimationHero from './universe-animation-hero'
import ValuesAndRevealingMap from './values-and-revealing-map'
import CountUpStatistics from './count-up-statistics'
import InsightsGridRecord from './insights-grid-record'
import InsightArticleRecord from './insight-article-record'
import RelatedInsightRecord from './related-insight-record'
import InsightIntroModule from './insight-intro-module'
import CollapsingImagesModule from './collapsing-pictures-module-record'
import ScrollableParagraphsWithLogo from './scrollable-paragraphs-with-logo'
import LinksListWithHoverEffect from './links-list-with-hover-effect'
import SlidingPicturesWithHeadline from './sliding-pictures-with-headline'
import ContactPopup from './contact-popup'
import ThreeColumnsRichTextWidget from './three-columns-rich-text-widget'
import TwoColumnsRichTextWidget from './two-columns-rich-text-widget'
import StickyGroup from './sticky-group'
import NewsBannerRecord from './news-banner-record'
import TextContentRecord from './text-content-record'
import LinksArrayRecord from './links-array-record'
import VideoModuleRecord from './video-module-record'
import FourColumnModule from './four-column-module'
import UspProductModule from './usp-product-module'
import TwoColumnHeroModule from './two-column-hero-module'
import SignUpFreeTrialModule from './sign-up-free-trial-module'
import TwoColumnAccordion from './two-column-accordion'
import SelectedInsights from './selected-insights'
import SubpageHero from './subpage-hero'
import SugarMarketForm from './sugar-market-form/sugar-market-form'
import ReportArticle from './report-article'
import HeroBannerWithNavigationalAnchor from './hero-banner-with-navigational-anchor'
import CustomSelectedInsights from './custom-selected-insights'
import CardGrid from './card-grid'
import Divider from './divider'
import RichTextWidgetWithBreadcrumbRecord from './rich-text-widget-with-breadcrumb-record'
import FeaturedWebinars from './featured-webinars'
import FeaturedEvents from './featured-events'

type Props = {
  blocks: BlockType[]
}

function Block({ block }: { block: BlockType }) {
  switch (block.__typename) {
    case 'FeaturedEventSwiperRecord':
      return <FeaturedWebinars block={block} />
    case 'FeaturedEventRecord':
      return <FeaturedEvents block={block} />
    case 'FrontPageServiceScrollAnimationRecord':
      return <FrontPageServiceScrollAnimation block={block} />
    case 'FrontPageKeyNewsElementRecord':
      return <FrontPageKeyNewsElement block={block} />
    case 'CategoryPageIntroDescriptionRecord':
      return <IntroDescriptionRecord block={block} />
    case 'PeopleCareerVideoImageModuleRecord':
      return <PeopleVideoModule block={block} />
    case 'RichTextWidgetRecord':
      return <RichTextWidgetRecord block={block} />
    case 'PeopleTeamGridRecord':
      return <TeamGrid block={block} />
    case 'FrontPageTopModuleRecord':
      return <FrontPageTopModule block={block} />
    case 'PressReleaseArticleRecord':
      return <PressReleaseArticle block={block} />
    case 'PressReleasePageSpotlightArticleRelatedNewsRecord':
      return <PressReleaseRelatedNews block={block} />
    case 'SolutionFrontpageEnergyTypeModuleRecord':
      return <SolutionsEnergyTypesModule block={block} />
    case 'SolutionHowWeDeliverValueModuleRecord':
      return <SolutionHowWeDeliverValue block={block} />
    case 'SolutionVideoImageModuleWithSideCaptionRecord':
      return <SolutionVideoImageModuleWithSideCaption block={block} />
    case 'CareersAboutModuleRecord':
      return <CareersAboutModule block={block} />
    case 'SolutionPageImageVideoHeaderRecord':
      return <SolutionPageImageVideoHeader block={block} />
    case 'SolutionsIndustryModuleRecord':
      return <SolutionsIndustryModule block={block} />
    case 'PressReleaseContactModuleRecord':
      return <PressReleaseContactModule block={block} />
    case 'SolutionSubMenuRecord':
      return <SolutionSubmenu block={block} />
    case 'CtaBlockRecord':
      return <Cta block={block} />
    case 'EmployeeCharacteristicRecord':
      return <EmployeeCharacteristics block={block} />
    case 'EmployeePublicationRecord':
      return <EmployeePublicationRecord block={block} />
    case 'CtaLinkRecord':
      return <CtaLinkRecord block={block} />
    case 'PressReleaseOverviewNewsGridRecord':
      return <PressReleaseOverviewNewsGrid block={block} />
    case 'PageHeaderRecord':
      return <PageHeader block={block} />
    case 'PressReleaseOverviewNewsletterRecord':
      return <PressReleaseOverviewNewsletter block={block} />
    case 'ContactsSliderWithCtaRecord':
      return <ContactsSliderWithCta block={block} />
    case 'FrontPageImageSliderRecord':
      return <FrontPageImageSlider block={block} />
    case 'ImageTextCtaModuleRecord':
      return <ImageTextCtaModule block={block} />
    case 'ServicesMenuRecord':
      return <ServicesMenu block={block} />
    case 'CareersOverviewPageJobPostingGridRecord':
      return <CareersOverviewPageJobPostingGrid block={block} />
    case 'DividedFeaturesBlockRecord':
      return <DividedFeatures block={block} />
    case 'TopModuleWithIntroDescriptionAndVideoImageModuleRecord':
      return (
        <TopModuleWithIntroDescriptionAndVideoImageModuleRecord block={block} />
      )
    case 'CareersConsultancyFullWidthImageModuleTopRecord':
      return <CareersConsultancyFullWidthImageModuleTopRecord block={block} />
    case 'CareersOverviewPageJoinUsWidgetRecord':
      return <CareersOverviewPageJoinUsWidgetRecord block={block} />
    case 'CareersHorizontalScrollRecord':
      return <CareersHorizontalScrollRecord block={block} />
    case 'CareersSpotlightModuleRecord':
      return <CareersSpotlightModule block={block} />
    case 'IndustryEnergyPageSolutionGridRecord':
      return <IndustryEnergyPageSolutionGridRecord block={block} />
    case 'IndustryGridRecord':
      return <IndustryGridRecord block={block} />
    case 'PreFooterBlockRecord':
      return <PreFooterBlockRecord block={block} />
    case 'PageNotFoundRecord':
      return <PageNotFound block={block} />
    case 'SearchResultRecord':
      return <SearchResult block={block} />
    case 'FrontpageFindTheRightSolutionRecord':
      return <FrontPageFindTheRightSolutionRecord block={block} />
    case 'ContactPageIntroductionTopModuleRecord':
      return <ContactPageIntroductionTopModule block={block} />
    case 'ContactPageContactInformationPerBusinessAreaModuleRecord':
      return (
        <ContactPageContactInformationPerBusinessAreaModule block={block} />
      )
    case 'ContactPageLocationsBlockRecord':
      return <ContactPageLocationBlock block={block} />
    case 'RichTextWidgetWithoutCtaRecord':
      return <RichTextWidgetWithoutCta block={block} />
    case 'EventsGridRecord':
      return <EventsGridRecord block={block} />
    case 'EnergyTypeGridRecord':
      return <EnegyTypeGridRecord block={block} />
    case 'TxtBlockRecord':
      return <TxtBlockRecord block={block} />
    case 'ClientTestimonialRecord':
      return <ClientTestimonialRecord block={block} />
    case 'LogoModuleRecord':
      return <LogoModuleRecord block={block} />
    case 'SpotlightArticleMainModuleRecord':
      return <SpotlightArticleMainModule block={block} />
    case 'OtherSpotlightsModuleRecord':
      return <OtherSpotlightsModuleRecord block={block} />
    case 'SolutionRelatedInsightRecord':
      return <SolutionRelatedInsightRecord block={block} />
    case 'InsightsGridRecord':
      return <InsightsGridRecord block={block} />
    case 'InsightArticleRecord':
      return <InsightArticleRecord block={block} />
    case 'RelatedInsightRecord':
      return <RelatedInsightRecord block={block} />
    case 'InsightIntroModuleRecord':
      return <InsightIntroModule block={block} />
    case 'SugarMarketProductDemoFormRecord':
      return <SugarMarketProductDemoFrom />
    case 'SugarMarketNewsletterFormRecord':
      return <SugarMarketNewsletterForm />
    case 'UniverseAnimationHeroRecord':
      return <UniverseAnimationHero block={block} />
    case 'SequencingSentencesAnimationRecord':
      return <SequencingSentences block={block} />
    case 'CompanyValuesWithMapRecord':
      return <ValuesAndRevealingMap block={block} />
    case 'CountUpStatisticsModuleRecord':
      return <CountUpStatistics block={block} />
    case 'CollapsingPicturesModuleRecord':
      return <CollapsingImagesModule block={block} />
    case 'SlidingPicturesWithHeadlineRecord':
      return <SlidingPicturesWithHeadline block={block} />
    case 'ScrollableParagraphsWithLogoRecord':
      return <ScrollableParagraphsWithLogo block={block} />
    case 'LinksListWithHoverEffectRecord':
      return <LinksListWithHoverEffect block={block} />
    case 'ContactPopupRecord':
      return <ContactPopup block={block} />
    case 'ThreeColumnsRichTextWidgetRecord':
      return <ThreeColumnsRichTextWidget block={block} />
    case 'TwoColumnsRichTextWidgetRecord':
      return <TwoColumnsRichTextWidget block={block} />
    case 'StickyGroupRecord':
      return <StickyGroup block={block} />
    case 'NewsBannerRecord':
      return <NewsBannerRecord block={block} />
    case 'TextContentRecord':
      return <TextContentRecord block={block} />
    case 'LinksArrayRecord':
      return <LinksArrayRecord block={block} />
    case 'VideoModuleRecord':
      return <VideoModuleRecord block={block} />
    case 'FourColumnModuleRecord':
      return <FourColumnModule block={block} />
    case 'UspProductModuleRecord':
      return <UspProductModule block={block} />
    case 'TwoColumnHeroRecord':
      return <TwoColumnHeroModule block={block} />
    case 'SignUpFreeTrialRecord':
      return <SignUpFreeTrialModule block={block} />
    case 'TwoColumnAccordionRecord':
      return <TwoColumnAccordion block={block} />
    case 'SelectedInsightRecord':
      return <SelectedInsights block={block} />
    case 'SubpageHeroRecord':
      return <SubpageHero block={block} />
    case 'SugarMarketFormRecord':
      return <SugarMarketForm block={block} />
    case 'ReportArticleRecord':
      return <ReportArticle block={block} />
    case 'HeroBannerWithNavigationalAnchorRecord':
      return <HeroBannerWithNavigationalAnchor block={block} />
    case 'CustomSelectedInsightRecord':
      return <CustomSelectedInsights block={block} />
    case 'CardGridRecord':
      return <CardGrid block={block} />
    case 'DividerRecord':
      return <Divider />
    case 'RichTextWidgetWithBreadcrumbRecord':
      return <RichTextWidgetWithBreadcrumbRecord block={block} />

    default:
      return <></>
  }
}

function Blocks({ blocks }: Props) {
  return (
    <>
      {blocks.map(
        (block) => block.__typename && <Block key={block.id} block={block} />
      )}
    </>
  )
}

export default Blocks
