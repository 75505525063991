import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

import { FeaturedEventRecord } from '../../generated/graphql'
import EventsCard from '../events-card'
import { Skeleton } from '../events-card/events-card'

type Props = {
  block: FeaturedEventRecord
}

function FeaturedEvents({ block }: Props) {
  const [data, setData] = useState<any>(null)
  const { query } = useRouter()

  const options = {
    headers: {
      RE_SECRET: process.env.NEXT_RE_EVENT_SECRET_KEY ?? '',
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    // forwards the query to the api
    body: JSON.stringify({ query }),
  }

  useEffect(() => {
    fetch(`api/events/public-events`, { ...options })
      .then((res) => res.json())
      .then(({ data }) => setData(data))
  }, [query])

  return (
    <section className="mx-auto mt-5 md:mt-10 p-2 md:p-5">
      <div className="grid gap-5 md:grid-cols-8">
        <div className="md:col-span-2">
          <h2 className="max-w-[310px] text-header-2">{block.title}</h2>
        </div>
        {data
          ? data.map((event: any, index: number) => {
              if (index < 2) {
                return (
                  <div key={event.id} className="md:col-span-3">
                    <EventsCard event={event} />
                  </div>
                )
              }
            })
          : Array(2)
              .fill(1)
              .map((_, index) => {
                return (
                  <div key={index} className="md:col-span-3">
                    <Skeleton />
                  </div>
                )
              })}
      </div>
    </section>
  )
}

export default FeaturedEvents
