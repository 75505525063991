import { Image } from 'react-datocms'
import Section from '../../components/section'
import ShareButton from '../../components/share-button'
import { InsightIntroModuleRecord } from '../../generated/graphql'
import CtaLink from '../../components/cta-link'
import usePaywallStore from '../../stores/use-paywall-store'
import useIsPaywallKeyUnlockedAllowed from '../../hooks/use-is-paywall-key-unlocked'
import {
  useInsightIsSecure,
  useInsightKey,
} from '../../contexts/insight-is-secure-context'
import { useRouter } from 'next/router'
import SugarMarketForm from '../sugar-market-form/sugar-market-form'

type Props = {
  block: InsightIntroModuleRecord
}

function InsightIntroModule({ block }: Props) {
  const isSecure = useInsightIsSecure()
  const router = useRouter()
  const insightKey = useInsightKey()
  const setCurrentPaywall = usePaywallStore((state) => state.setCurrentPaywall)

  const isVisible = useIsPaywallKeyUnlockedAllowed(insightKey)

  const onClickContinueReading = () => {
    setCurrentPaywall({
      key: insightKey,
      headline: block.headline ?? '',
      sugarMarketUrl: block.continueReading?.[0].link ?? '',
    })
    router.push('/authentication-form')
  }

  return (
    <Section>
      <div
        className={`col-span-full flex flex-col gap-5 font-medium ${
          block.showSugarMarketForm ? 'lg:col-span-4' : 'lg:col-span-5'
        }`}
      >
        <h2 className="text-header-1">{block.headline}</h2>
        <div
          className="flex flex-col text-text-manchet prose"
          dangerouslySetInnerHTML={{ __html: block.excerpt || '' }}
        />
        {block.showSugarMarketForm &&
          block.showImageWithSugarMarketForm &&
          block.image &&
          block.image.responsiveImage && (
            <Image
              style={{
                maxWidth: '400px',
                width: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              data={block.image.responsiveImage}
            />
          )}
      </div>

      {block.showSugarMarketForm && block.htmlFileForForm?.url ? (
        <div className="col-span-full lg:col-span-4">
          <SugarMarketForm
            block={{ htmlFile: block.htmlFileForForm }}
            classOverrideInnerGrid="gap-0 md:gap-0 md:p-0"
          />
        </div>
      ) : (
        <div className="col-span-full lg:col-span-3">
          {block.image && block.image.responsiveImage && (
            <Image
              style={{
                maxWidth: 'none',
                width: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              data={block.image.responsiveImage}
            />
          )}
        </div>
      )}

      <div
        className={`col-span-full flex flex-col items-start justify-between gap-y-1 border-t-2 border-greys-1 pt-3 ${
          block.showSugarMarketForm ? 'lg:col-span-4' : 'lg:col-span-5'
        }`}
      >
        <ShareButton
          mobileColumn={true}
          text={block?.shareButtonLink?.text!}
          variant="primary"
          icons={block.shareButtonLink?.socialMediaButtons!}
        />
        {isSecure &&
          !isVisible &&
          block.continueReading?.map((cta) => (
            <div key={cta.id}>
              <CtaLink onClick={onClickContinueReading} cta={cta} />
            </div>
          ))}
      </div>
    </Section>
  )
}

export default InsightIntroModule
