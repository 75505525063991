import { useContext, useEffect, useMemo, useState } from 'react'
import Section from '../../components/section'
import EventListItem from '../../types/EventListItem'
import EventsThumbnail from '../events-thumbnail'
import LoadMore from '../../components/load-more-button'
import mapEventsToMonths from '../../utils/map-events-to-months'
import { useRouter } from 'next/router'
import Event from '../../types/Event'
import EventsFilterContainer from './events-filter-container'
import EventContext from './event-context'
import _ from 'lodash'

type Props = {
  block: any
}

const activeColor = 'bg-greys-2 rounded-full'

function EventsGridRecord({ block }: Props) {
  const { events, setEvents } = useContext(EventContext)

  const [pastEvents, setPastEvents] = useState<EventListItem[]>(
    events.pastEvents
  )
  const [upcomingEvents, setUpcomingEvents] = useState<EventListItem[]>(
    events.upcomingEvents
  )

  const [isFutureEvents, setIsFutureEvents] = useState(true)

  const [hasMorePastEvents, setHasMorePastEvents] = useState(true)
  const [hasMoreUpcomingEvents, setHasMoreUpcomingEvents] = useState(true)
  const [pagination, setPagination] = useState(0)
  const { query, pathname } = useRouter()

  const fetchEvents = async (pagination: number) => {
    const rawNewEvents = await fetch('/api/events', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        pagination,
        isOnline: pathname === '/webinars',
      }),
    })

    const newEvents = await rawNewEvents.json()

    return newEvents
  }

  useEffect(() => {
    const getQueriedEvents = async () => {
      const {
        upcomingEvents: newUpcomingEvents,
        pastEvents: newPastEvents,
        paginationHeaders,
      } = await fetchEvents(0)

      updateEvents(newPastEvents, newUpcomingEvents, paginationHeaders)

      // Reset pagination since we are fetching for new query
      setPagination(0)
    }

    getQueriedEvents()
  }, [query])

  const updateEvents = (
    pastEvents: EventListItem[],
    upcomingEvents: EventListItem[],
    paginationHeaders: any
  ) => {
    console.log({ block })
    setEvents({ pastEvents, upcomingEvents })
    setPastEvents(pastEvents)
    setUpcomingEvents(upcomingEvents)

    const { pastEvents: pastPagination, upcomingEvents: upcomingPagination } =
      paginationHeaders

    setHasMorePastEvents(pastPagination.NextPage)
    setHasMoreUpcomingEvents(upcomingPagination.NextPage)
  }

  const handleLoadMore = async () => {
    const nextPage = await fetchEvents(pagination + 1)

    const newPastEvents = [...pastEvents, ...nextPage.pastEvents]
    const newUpcomingEvents = [...upcomingEvents, ...nextPage.upcomingEvents]

    updateEvents(newPastEvents, newUpcomingEvents, nextPage.paginationHeaders)

    setPagination(pagination + 1)
  }

  const monthlyPastEvents: Record<string, EventListItem[]> = useMemo(() => {
    return mapEventsToMonths(pastEvents)
  }, [pastEvents])
  const monthlyUpcomingEvents: Record<string, EventListItem[]> = useMemo(() => {
    return mapEventsToMonths(upcomingEvents)
  }, [upcomingEvents])

  return (
    <Section>
      <div id="events-section" className='z-10 col-span-8'>
        {block.showDivider && <hr className="col-span-8 border-t-2 border-greys-2 mb-8" />}
        <div
          className={
            'col-span-6 grid grid-cols-1 gap-1 text-text-base md:grid-cols-2 md:pl-[26%]'
          }
        >
          <div className="flex flex-row">
            <button
              onClick={() => {
                setIsFutureEvents(true)
              }}
              className={`px-2 py-1 ${isFutureEvents ? activeColor : ''}`}
            >
              {block.futureButton === 'Upcoming' ? 'Our events' : block.futureButton}
            </button>
            <button 
              onClick={() => {
                setIsFutureEvents(false)
              }}
              className={`px-2 py-1 ${!isFutureEvents ? activeColor : ''}`}
            >
              {block.pastButton === 'Past' ? 'External events' : block.pastButton}
            </button>
          </div>
        </div>

        <div>
          {isFutureEvents &&
            monthlyUpcomingEvents &&
            Object.keys(monthlyUpcomingEvents).map((month) => (
              <div className={'relative z-10 col-span-8 pt-2'} key={month}>
                <div
                  className={
                    'top-4 col-span-6 hidden text-header-2-5 text-primary-rystad-blue sm:col-span-2 md:sticky md:block'
                  }
                >
                  <h2 className="relative top-10">{month}</h2>
                </div>

                <div
                  className={
                    'col-span-6 grid grid-cols-1 gap-5 md:grid-cols-2 md:pl-[26%]'
                  }
                >
                  {monthlyUpcomingEvents?.[month].map((event) => (
                    <EventsThumbnail key={event.id} event={event} />
                  ))}
                </div>
              </div>
            ))}

          {!isFutureEvents &&
            monthlyPastEvents &&
            Object.keys(monthlyPastEvents).map((month) => (
              <div className={'relative z-10 col-span-8 pt-2'} key={month}>
                <div
                  className={
                    'top-4 col-span-6 hidden text-header-2-5 text-primary-rystad-blue sm:col-span-2 md:sticky md:block'
                  }
                >
                  <h2 className="relative top-10">{month}</h2>
                </div>

                <div
                  className={
                    'col-span-6 grid grid-cols-1 gap-5 md:grid-cols-2 md:pl-[26%]'
                  }
                >
                  {monthlyPastEvents?.[month].map((event) => (
                    <EventsThumbnail key={event.id} event={event} />
                  ))}
                </div>
              </div>
            ))}

          {(isFutureEvents && !upcomingEvents.length) ||
          (!isFutureEvents && !pastEvents.length) ? (
            <div className={'relative z-10 col-span-8 pt-2'}>
              <div
                className={
                  'col-span-8 grid grid-cols-1 gap-5 md:grid-cols-2 md:pl-[26%]'
                }
              >
                <h2 className="relative">{block.noResultsText}</h2>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {((isFutureEvents && hasMoreUpcomingEvents) ||
        (!isFutureEvents && hasMorePastEvents)) && (
        <LoadMore onClick={handleLoadMore} variant="dark" disabled={false} />
      )}

      <EventsFilterContainer />
    </Section>
  )
}

export default EventsGridRecord
